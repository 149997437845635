<template>
  <div>
    <div :style="{ display: 'flex', gap: '16px' }">
      <feather-icon
        v-if="currentUser.is_admin"
        v-b-tooltip.hover.lefttop.v-primary
        title="Editar informações de colaborador"
        icon="EditIcon"
        size="15"
        style="cursor: pointer"
        @click="
          $router.push(
            `/editar-titulo-tarefa/${item.id}`
          )
        "
      />

      <feather-icon
        v-if="currentUser.is_admin"
        v-b-tooltip.hover.lefttop.v-primary
        title="Editar informações de colaborador"
        icon="TrashIcon"
        size="15"
        style="cursor: pointer"
        @click="
         deleteModal = true
        "
      />
    </div>

    <b-modal centered v-model="deleteModal" hide-footer title="Atenção">
      <span>Deseja excluir o titulo: </span>
      <strong> {{ `${item.task_title}` }}</strong>
      <br />
      <div
        :style="{
          paddingTop: '30px',
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          justifyContent: 'center',
        }"
      >
        <b-button variant="danger" @click="deleteTaskName" >Excluir</b-button>
        <b-button variant="outline-secondary" @click="deleteModal = false">Cancelar</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VBTooltip, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'actions-supplier',
  components: {
    BButton
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
  },
  data: () => ({
    member_info: {},
    deleteModal: false,
  }),
  created() { },
  methods: {
    deleteTaskName(){
      this.$store
        .dispatch('deleteTaskTitle', {
          id: this.item.id,
        })
        .then((resp) => {
          if (resp) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Titulo excluido com sucesso',
                icon: 'SuccessIcon',
                variant: 'success',
              },
            });
            this.$emit('taskDeleted'); 
          }
          this.deleteModal = false;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao excluir titulo',
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          });
          console.log(err);
          this.deleteModal = false;

        });
    }
  },
};
</script>
