var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({ display: 'flex', gap: '16px' })},[(_vm.currentUser.is_admin)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":"Editar informações de colaborador","icon":"EditIcon","size":"15"},on:{"click":function($event){return _vm.$router.push(
          ("/editar-titulo-tarefa/" + (_vm.item.id))
        )}}}):_vm._e(),(_vm.currentUser.is_admin)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.lefttop.v-primary",modifiers:{"hover":true,"lefttop":true,"v-primary":true}}],staticStyle:{"cursor":"pointer"},attrs:{"title":"Editar informações de colaborador","icon":"TrashIcon","size":"15"},on:{"click":function($event){_vm.deleteModal = true}}}):_vm._e()],1),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":"Atenção"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('span',[_vm._v("Deseja excluir o titulo: ")]),_c('strong',[_vm._v(" "+_vm._s(("" + (_vm.item.task_title))))]),_c('br'),_c('div',{style:({
        paddingTop: '30px',
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'center',
      })},[_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.deleteTaskName}},[_vm._v("Excluir")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Cancelar")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }