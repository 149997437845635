<template>
  <b-card title="">
    <b-row class="justify-content-between align-items-center mx-0">
      <b-col class="align-items-center">
        <b-row class="my-1 text-right mt-2 d-flex justify-content-start">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col class="align-items-center d-flex justify-content-end">
        <b-button
          v-if="currentUser.is_admin"
          variant="primary"
          @click="$router.push('/novo-titulo-tarefa')"
          class="mr-1"
        >
          Novo título
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :fields="fields"
      :items="filter()"
      class="text-left"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsSuppliers
          :item="item"
          :currentUser="currentUser"
          :member_id="member_id"
          @taskDeleted="getAll"
        />
      </template>
    </b-table>
    <b-pagination
      class="justify-content-center"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @change="getPage"
      aria-controls="my-table"
    ></b-pagination>
    <div class="mr-2 d-flex justify-content-end">
      <strong>Total de títulos: {{ totalRows }} </strong>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BPagination,
} from 'bootstrap-vue';
import ActionsSuppliers from './components/ActionsTaskTitles.vue';
//import moment from 'moment'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCard,
    BRow,
    BTable,
    BFormInput,
    BFormGroup,
    BCol,
    BButton,
    ActionsSuppliers,
    BPagination,
  },
  data: () => ({
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        thStyle: { width: '20%' },
      },
      {
        key: 'task_title',
        label: 'Título',
        sortable: true,
      },
    ],
    items: [],
    search: '',
    perPage: 30,
    rows: 0,
    currentPage: 1,
    member_id: 0,
    totalRows: 0,
    taxRecordsList: false,
  }),
  created() {
    this.getAll();
  },

  computed: {
    currentUser() {
      return this.$store.getters.user.accesses.find(
        (a) => a.workspace_id == this.$store.getters.currentWorkspace.id
      );
    },
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },

  methods: {
    getPage(val) {
      this.currentPage = val;
      this.getAll();
    },
    async getAll() {
      this.$store
        .dispatch('getAllTaskTitles', {
          workspace_id: this.currentWorkspace.id,
          page: this.currentPage,
          perPage: this.perPage,
          inactive: false,
        })
        .then((resp) => {
          if (resp) {
            this.items = resp.data;
            this.totalRows = resp.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    filter() {
      if (this.search.trim() === '') {
        return this.items;
      } else {
        return this.items.filter((item) => {
          return (
            item.username.toLowerCase().indexOf(this.search.toLowerCase()) >
              -1 ||
            item.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    handleUpdateList() {
      this.getAll();
    },
  },
};
</script>
